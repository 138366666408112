import React from 'react';
import ListItemButton from 'components/ui-components-v2/ListItemButton';
import Icon from 'components/ui-components-v2/Icon';
import Layer from 'components/template-designer/types/layer.type';
import Checkbox from 'components/ui-components-v2/Checkbox';
import { DynamicLayerHelpers } from 'components/template-designer/helpers/dynamic-layer.helpers';
import { Attributes } from 'components/template-designer/types/attribute.type';
import { TEMPLATE_DESIGNER_TOOLTIP_DELAY } from 'components/template-designer/constants';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import Template, { View } from 'components/template-designer/types/template.type';
import Tooltip from 'components/ui-components-v2/Tooltip';
import { FeedMappingHelpers } from 'components/template-designer/helpers/feed-mapping.helpers';
import { InputAttribute } from '../../dynamic-layers/types/attribute.type';
import { Selected } from '..';
import '../styles/dynamic-layer-input-dialog-attributes-list.scss';

interface Props {
    title: string;
    list: InputAttribute<unknown>[];
    selected: Selected;
    alreadySelected: Selected;
    onSelectionChange: (layerKey: Layer['key'], attribute: Attributes) => void;
    selectedLayer: Layer;
    showLinkedInputs?: boolean;
}

const DynamicLayerInputDialogAttributesList = ({ title, list, selected, alreadySelected, onSelectionChange, selectedLayer, showLinkedInputs }: Props) => {
    const { frameType, feedMapping } = useComponentStore<{ frameType: View['frameType']; feedMapping: Template['dataVariables'] }>('TemplateDesigner', {
        fields: {
            frameType: 'view.frameType',
            feedMapping: 'dataVariables'
        }
    });

    return (
        <div className="template-designer__dynamic-layer-input-dialog-attributes-list">
            <div className="template-designer__dynamic-layer-input-dialog-attributes-list__title">{title}</div>

            {list.map((input) => {
                const isAlreadySelected = (alreadySelected[selectedLayer.key] || []).includes(input.attribute);
                const isSelected = (selected[selectedLayer.key] || []).includes(input.attribute);
                const isLinked = FeedMappingHelpers.isLinkedToInput(feedMapping[frameType], selectedLayer.key, input.attribute);
                const isDisabled = input?.isDisabled?.(selectedLayer);
                const disabled = isAlreadySelected || isDisabled;
                const tooltip = isDisabled ? input.disabledDescription : input.description;

                return (
                    <Tooltip title={tooltip} key={input.attribute} placement="right" enterDelay={TEMPLATE_DESIGNER_TOOLTIP_DELAY} arrow>
                        <div>
                            <ListItemButton
                                className="template-designer__dynamic-layer-input-dialog-attributes-list__attribute"
                                onClick={() => onSelectionChange(selectedLayer.key, input.attribute)}
                                disabled={disabled}
                                data-cy={input.dataCy}>
                                <Checkbox
                                    className="template-designer__dynamic-layer-input-dialog-attributes-list__attribute__checkbox"
                                    size="small"
                                    color="primary"
                                    checked={isAlreadySelected || isSelected}
                                    disabled={disabled}
                                />

                                <Icon className="template-designer__dynamic-layer-input-dialog-attributes-list__attribute__icon">
                                    {DynamicLayerHelpers.getDynamicLayerIcon(input.attribute)}
                                </Icon>

                                <div className="template-designer__dynamic-layer-input-dialog-attributes-list__attribute__label">{input.label}</div>

                                {showLinkedInputs && isLinked && (
                                    <Icon className="template-designer__dynamic-layer-input-dialog-attributes-list__attribute__linked">link</Icon>
                                )}
                            </ListItemButton>
                        </div>
                    </Tooltip>
                );
            })}
        </div>
    );
};

export { DynamicLayerInputDialogAttributesList };
